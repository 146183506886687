/**
 * Created by Michał on 11.05.2017.
 */

import $ from 'jquery';
// import * as asyncCarousel from './asyncCarousel';
// import * as attributeSlider from './attributeSlider';
// import * as cart from './cart';
// import * as categoryCollapse from './categoryCollapse';
import * as categoryTabs from './categoryTabs';
// import * as controlViews from './controlViews';
import * as dynamicTabs from './dynamicTabs';
// import * as filterAttributes from './filterAttributes';
// import * as filterBox from './filterBox';
// import * as hiddenFilterOptions from './hiddenFilterOptions';
// import * as menuPromotions from './menuPromotions';
// import * as mobileFilterNavigation from './mobileFilterNavigation';
import * as mobileNavigation from './mobileNavigation';
// import * as priceSlider from './priceSlider';
// import * as productPackages from './productPackages';
// import * as productQty from './productQty';
// import * as shippingMethods from './shippingMethods';
// import * as shopSearch from './shopSearch';
// import * as stayHomeBanner from './stayHomeBanner';
// import * as stickyNavbar from './stickyNavbar';
// import * as stickySidebar from './stickySidebar';
// import * as seriesCollection from './seriesCollection';
// import * as inspirationProducts from './inspirationProducts';
// import * as inspirationFilters from './inspirationFilters';
// import f from './filters';


function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}

function toggleMenu(){
    var menu = $('#mobile-menu');
    var wrapper = $('#wrapper');
    var content = $('#content');
    $('.js-tg-menu').toggleClass("active");
    $('#mask').toggleClass('shown');
    menu.toggleClass("toggled");
    content.toggleClass("toggled");
    if(menu.hasClass("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        $('body').css('overflow', 'hidden');
        $('html').css('overflow', 'hidden');
    }
    else {
        // wrapper.css('height', 'auto');productQty
        $('body').css('overflow', 'auto');
        $('html').css('overflow', 'auto');
    }
    //
}

$(document).ready(function(){
    $('.js-tg-menu').on('click', function (e) {
        e.preventDefault();
        toggleMenu();
    });
    $('#mask').on('click', function (e) {
        toggleMenu();
    });

    // $('.js-homepage-slider').owlCarousel({
    //     lazyLoad: true,
    //     items: 1,
    //     nav: true,
    //     dots: false,
    //     autoplay: true,
    //     loop: true,
    //     autoplayHoverPause: true,
    //     autoplayTimeout: 7000,
    //     smartSpeed: 500,
    //     slideBy: 1,
    //     navText : ["<img src='/wp-content/themes/elazienki/assets/images/slider/slider-arrow-left.png' />", "<img src='/wp-content/themes/elazienki/assets/images/slider/slider-arrow-right.png' />"],
    // });
    // $('.js-product-slider').owlCarousel({
    //     loop:true,
    //     margin:10,
    //     nav:true,
    //     dots: false,
    //     slideBy: 4,
    //     navText : ["<img src='/wp-content/themes/elazienki/assets/images/slider/slider-arrow-left.png' />", "<img src='/wp-content/themes/elazienki/assets/images/slider/slider-arrow-right.png' />"],
    //     responsive: {
    //         0: {
    //             items: 1,
    //             slideBy: 1
    //         },
    //         660: {
    //             items: 2,
    //             slideBy: 2,
    //         },
    //         991: {
    //             items: 3,
    //             slideBy: 3
    //         },
    //         1199: {
    //             items: 4,
    //             slideBy: 4
    //         }
    //     }
    // });
    //
    // var inspirationProductsCarousel = $('.js-inspiration-products');
    // if (inspirationProductsCarousel.length > 0) {
    //     var max_slides = 2;
    //     var width = $(window).width();
    //     if (width > 0 && width <= 659) {
    //         max_slides = 2;
    //         inspirationProductsCarousel.addClass('product-slider--100-width');
    //     } else if (width >= 660 && width <= 990) {
    //         max_slides = 3;
    //     } else max_slides = 2;
    //     if (inspirationProductsCarousel.find('.single-product').length > max_slides) {
    //         inspirationProductsCarousel.owlCarousel({
    //             loop: true,
    //             margin: 10,
    //             nav: true,
    //             dots: false,
    //             slideBy: 2,
    //             navText: ['<div class="product-slider__arrow product-slider__arrow--right product-slider__arrow--small"></div>', '<div class="product-slider__arrow product-slider__arrow--left product-slider__arrow--small"></div>'],
    //             responsive: {
    //                 0: {
    //                     items: 2,
    //                     // autoplay: 3000,
    //                     // nav: false
    //                 },
    //                 991: {
    //                     items: 2,
    //                     // autoplay: 3000,
    //                     // nav: false
    //                 }
    //             }
    //         });
    //     } else {
    //         inspirationProductsCarousel.removeClass('owl-carousel').removeClass('owl-theme').addClass('product-slider--static');
    //     }
    // }
    //
    // // owl carousel product gallery sliders
    // var $gallery_main_carousel = $('.js-single-product-thumbnails-slider');
    // var $gallery_thumb_carousel = $('.js-single-product-thumbnails-slider-nav');
    // var slidesPerPage = 3; //globaly define number of elements per page
    // var syncedSecondary = true;
    //
    // $gallery_main_carousel.owlCarousel({
    //     items: 1,
    //     slideSpeed: 2000,
    //     nav: false,
    //     autoplay: false,
    //     dots: false,
    //     loop: false,
    //     responsiveRefreshRate: 200
    // }).on('changed.owl.carousel', syncPosition);
    //
    // $gallery_thumb_carousel.on('initialized.owl.carousel', function() {
    //     $gallery_thumb_carousel.find(".owl-item").eq(0).addClass("current");
    // }).owlCarousel({
    //     items: slidesPerPage,
    //     loop: false,
    //     dots: false,
    //     nav: true,
    //     smartSpeed: 200,
    //     slideSpeed: 500,
    //     slideBy: 1, //alternatively you
    //     responsiveRefreshRate: 100,
    //     navText: ['<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--left single-product-thumbnails-slider__arrow--small"></div>', '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--right single-product-thumbnails-slider__arrow--small"></div>']
    // });
    //
    // var $inspiration_gallery = $('.js-inspiration-gallery');
    // $inspiration_gallery.owlCarousel({
    //     items: 5,
    //     loop: false,
    //     dots: false,
    //     nav: true,
    //     smartSpeed: 200,
    //     slideSpeed: 500,
    //     slideBy: 1, //alternatively you
    //     responsiveRefreshRate: 100,
    //     responsive: {
    //         0: {
    //             items: 3
    //         },
    //         660: {
    //             items: 4
    //         },
    //         1024: {
    //             items: 5
    //         }
    //     },
    //     navText: ['<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--left single-product-thumbnails-slider__arrow--small"></div>', '<div class="single-product-thumbnails-slider__arrow single-product-thumbnails-slider__arrow--right single-product-thumbnails-slider__arrow--small"></div>']
    // });
    //
    // function syncPosition(el) {
    //     //if you set loop to false, you have to restore this next line
    //     var current = el.item.index;
    //
    //     //if you disable loop you have to comment this block
    //     // var count = el.item.count - 1;
    //     // var current = Math.round(el.item.index - (el.item.count / 2) - .5);
    //     //
    //     // if (current < 0) {
    //     //     current = count;
    //     // }
    //     // if (current > count) {
    //     //     current = 0;
    //     // }
    //
    //     //end block
    //
    //     $gallery_thumb_carousel
    //         .find(".owl-item")
    //         .removeClass("current")
    //         .eq(current)
    //         .addClass("current");
    //     var onscreen = $gallery_thumb_carousel.find('.owl-item.active').length - 1;
    //     var start = $gallery_thumb_carousel.find('.owl-item.active').first().index();
    //     var end = $gallery_thumb_carousel.find('.owl-item.active').last().index();
    //
    //     if (current > end) {
    //         $gallery_thumb_carousel.data('owl.carousel').to(current, 100, true);
    //     }
    //     if (current < start) {
    //         $gallery_thumb_carousel.data('owl.carousel').to(current - onscreen, 100, true);
    //     }
    // }
    //
    // function syncPosition2(el) {
    //     if (syncedSecondary) {
    //         var number = el.item.index;
    //         $gallery_main_carousel.data('owl.carousel').to(number, 100, true);
    //     }
    // }
    //
    // $gallery_thumb_carousel.on("click", ".owl-item", function(e) {
    //     e.preventDefault();
    //     var number = $(this).index();
    //     $gallery_main_carousel.data('owl.carousel').to(number, 300, true);
    // });

    $('.js-dynamic-tabs').dynamicTabs();

    $('.js-category-tabs').categoryTabs();

    // $('.js-attribute-filter').filterAttributes();

    // var $fv = $('.js-fv-checkbox');
    // if ($fv.length > 0) {
    //
    //     // console.log($fv);
    //
    //     var $fields_to_hide = $fv.closest('.box__content').find('.js-fvat-to-hide');
    //     var $fields_to_show = $fv.closest('.box__content').find('.js-fvat-to-show');
    //
    //     // console.log($fv);
    //
    //     $.each($fields_to_show, function(){
    //         $(this).hide();
    //     });
    //
    //     $fv.on('change', function() {
    //         // console.log('change');
    //         if ($(this).is(':checked')) {
    //             $.each($fields_to_show, function(){
    //                 $(this).show();
    //             });
    //             $.each($fields_to_hide, function(){
    //                 $(this).hide();
    //             });
    //         } else {
    //             $.each($fields_to_show, function(){
    //                 $(this).hide();
    //             });
    //             $.each($fields_to_hide, function(){
    //                 $(this).show();
    //             });
    //         }
    //     });
    // }
    // $('.js-single-product-qty').productQty();
    // if ($('.js-mobile-menu').length > 0) {
    //     var $close = $('[data-element="close"]');
    //     $close.on('click', function(e) {
    //         e.preventDefault();
    //
    //         $('.js-tg-menu').removeClass('active');
    //         $('.mobile-menu').removeClass('toggled');
    //         $('#mask').removeClass('shown');
    //         $('body').css('overflow', 'auto');
    //         $('html').css('overflow', 'auto');
    //     });
    // }

    $('.js-mobile-navigation').mobileNavigation();
    // $('.js-product-list').controlViews();
    // $('.js-shipping-methods').shippingMethods();
    //
    // $('.js-promotion-box').menuPromotion();

    if ($('.js-navigation').length > 0) {
        var $navigation = $('.js-navigation');

        var $navi_element_has_submenu = $navigation.find('> li.navigation__element.navigation__element--has-submenu');
        var $sub_navigation_container = $navi_element_has_submenu.find('.sub-navigation-container');
        var $first_element = $sub_navigation_container.find('> .navigation').find('li:first-of-type');
        $first_element.addClass('active f-e');

        $navi_element_has_submenu.hover(function() {
            $(this).find('li').removeClass('active');
            $first_element.addClass('active');
        }, function() {});

        $sub_navigation_container.find('li a').hover(function() {
            $(this).parent().parent().find('li').removeClass('active');
            $(this).parent().addClass('active');
        }, function() {});

        var once = false;
        var contentLoaded = false;
        $navigation.find('li:first-of-type').on('mouseover', function(e) {
            var $promotionBox = $(this).find('.sub-navigation-container').find('> .navigation').find('li:first-of-type').find('.js-promotion-box');
            var cat = $promotionBox.attr('data-category');
            var loader = $promotionBox.find('[data-element="loader"]');

            if (!contentLoaded) {
                if (!once) {
                    loader.addClass('shown');
                    once = true;
                    $.ajax({
                        url: '/wp-admin/admin-ajax.php',
                        type: "POST",
                        data: {
                            action: 'nt_elaz_menu_promotions',
                            category: cat
                        },
                        success: function (response) {
                            loader.removeClass('shown');
                            $promotionBox.append(response);
                            contentLoaded = true;
                        },
                        error: function () {
                            loader.removeClass('shown');
                        }
                    });
                }
            }
        });
    }
    //
    // $('.single-product').on('click, mouseup', function(e) {
    //     var href = $(this).attr('data-href');
    //     var el = $(e.target);
    //
    //     if (el.prop('nodeName') !== 'A' && e.which !== 3) {
    //         e.preventDefault();
    //         if (e.ctrlKey) {
    //             window.open(href, '_blank');
    //         } else if (e.which === 2) {
    //             window.open(href, '_blank');
    //         } else window.location.href = href;
    //
    //     }
    // });
    // $('.js-sticky-sidebar').stickySidebar();
    // $('.js-hidden-filter-options').hiddenFilterOptions();
    // $('.js-filter-box').filterBox();
    //
    // $('.js-sticky-navbar').stickyNavbar();
    // $('.js-category-description-collapse').categoryCollapse();
    // //
    // $('.js-shop-search').shopSearch();
    //
    // if ($(window).width() <= 991) {
    //     $('.js-mobile-filter-navigation').mobileFilterNavigation();
    //     $('.js-category-mobile-filters').categoryTabs();
    //     $('.js-category-mobile-filters').find('.tab').removeClass('active');
    // }
    // //
    // // if ($(window).width() <= 650) {
    // //     $('.js-cart').on('click', function (e) {
    // //         e.preventDefault();
    // //         if($('.js-side-cart').hasClass('side-cart--shown')){
    // //             $('.js-side-cart').removeClass('side-cart--shown');
    // //             $('body').css('overflow', 'auto');
    // //             $('html').css('overflow', 'auto');
    // //         } else {
    // //             $('.js-side-cart').addClass('side-cart--shown');
    // //             $('body').css('overflow', 'hidden');
    // //             $('html').css('overflow', 'hidden');
    // //         }
    // //     });
    // $(window).on('scroll', function(e) {
    //     if($('.js-side-cart').hasClass('side-cart--shown')){
    //         $('.js-side-cart').removeClass('side-cart--shown');
    //         // $('body').css('overflow', 'auto');
    //         // $('html').css('overflow', 'auto');
    //     }
    // });
    //
    // $(document).on('click', function(e) {
    //     var $cart = $('.js-cart');
    //     var $side_cart = $('.js-side-cart');
    //
    //     if ($side_cart.hasClass('side-cart--shown')) {
    //         if ($cart.has($(e.target)).length === 0 && $side_cart.has($(e.target)).length === 0 ) {
    //             $side_cart.removeClass('side-cart--shown');
    //             $('body').css('overflow', 'auto');
    //             $('html').css('overflow', 'auto');
    //         }
    //     }
    // });
    //
    // $('.basket-btn').on('click', function (e) {
    //     e.preventDefault();
    //
    //     var side_cart = $('.js-side-cart');
    //     if (side_cart.length > 0) {
    //         if(side_cart.hasClass('side-cart--shown')){
    //             $('.js-side-cart').removeClass('side-cart--shown');
    //             $('body').css('overflow', 'auto');
    //             $('html').css('overflow', 'auto');
    //         } else {
    //             side_cart.addClass('side-cart--shown');
    //             $('body').css('overflow', 'hidden');
    //             $('html').css('overflow', 'hidden');
    //         }
    //     }
    // });
    //
    // $('.js-cart-close').on('click', function(e) {
    //     e.preventDefault();
    //     if($('.js-side-cart').hasClass('side-cart--shown')){
    //         $('.js-side-cart').removeClass('side-cart--shown');
    //         $('body').css('overflow', 'auto');
    //         $('html').css('overflow', 'auto');
    //     }
    // });
    // // }
    // $('.js-search-btn').on('click', function(e) {
    //     e.preventDefault();
    //
    //     var $search = $('#sticky-shop-search');
    //     if ($search.length > 0) {
    //         if ($search.parent().hasClass('shown')) {
    //             $search.parent().removeClass('shown');
    //         } else $search.parent().addClass('shown');
    //     }
    // });
    // $('.js-product-packages').productPackages();
    // $('.js-mobile-tabs').mobileTabs();
    // $('.js-attribute-slider').attributeSlider();
    // $('.js-async-carousel').asyncCarousel();
    // $('.js-check-products').checkProducts();
    //
    // $('.js-series-collection').seriesCollection();
    //
    // $('#order_review').on('change', '.js-return-guarantee', function(e) {
    //     var $checkbox = $(this);
    //     var $tr = $checkbox.closest('tr').parent().find('[data-element="row-info"]');
    //
    //     if ($checkbox.is(':checked')) {
    //         $tr.slideDown();
    //     } else {
    //         $tr.slideUp();
    //     }
    // });
    //
    // var cart = null;
    // if ($('.js-side-cart').length > 0) {
    //     cart = $('.js-side-cart').cart();
    //     // console.log(cart);
    //     $('body').on('click', '.js-add-to-cart', cart.addToCart);
    //     $('body').on('click', '.js-add-to-cart-with-qty', cart.addToCartWithQty);
    // }
    // // console.log(cart);
    // $('body').on('click', '.js-add-to-cart', function(e) {
    //     e.preventDefault();
    //     // console.log('click js-add-to-cart');
    // });
    //
    // if ($('.js-stay-home-banner').length > 0) {
    //     $('.js-stay-home-banner').stayHomeBanner();
    // }
    //
    // if ($('.js-contact-form').length > 0) {
    //     var $form = $('.js-contact-form');
    //     var $formStatus = $form.find('.form__status');
    //     var $formLoader = $form.find('.form__loader');
    //     var $formMessageSuccess = $form.find('.form__success');
    //     var $formMessageError = $form.find('.form__error');
    //     $form.on('submit', function(e) {
    //         e.preventDefault();
    //
    //         $formStatus.css({ 'display' : 'flex' });
    //         $formLoader.css({ 'display' : 'inline-block' });
    //         setTimeout(function() {
    //             var data = {
    //                 first_name: '', email: '', subject: '', message: '', grecaptcha: '', action: 'contact_form'
    //             };
    //             if ($form.find('[name="first_name"]').length > 0)
    //                 data.first_name = $form.find('[name="first_name"]').val();
    //
    //             if ($form.find('[name="email"]').length > 0)
    //                 data.email = $form.find('[name="email"]').val();
    //
    //             if ($form.find('[name="subject"]').length > 0)
    //                 data.subject = $form.find('[name="subject"]').val();
    //
    //             if ($form.find('[name="message"]').length > 0)
    //                 data.message = $form.find('[name="message"]').val();
    //
    //             if ($form.find('[name="g-recaptcha-response"]').length > 0)
    //                 data.grecaptcha = $form.find('[name="g-recaptcha-response"]').val();
    //
    //             jQuery.ajax({
    //                 url: '/wp-admin/admin-ajax.php',
    //                 type: "POST",
    //                 data: data,
    //                 dataType: 'json',
    //                 success: function (response) {
    //                     if (response.status === 'success') {
    //                         $formLoader.css({ 'display' : 'none' });
    //                         $formMessageSuccess.css({ 'display' : 'block' });
    //                         setTimeout(function () {
    //                             $form.find('[name="first_name"]').val('');
    //                             $form.find('[name="email"]').val('');
    //                             $form.find('[name="subject"]').val('');
    //                             $form.find('[name="message"]').val('');
    //                             $form.find('[name="rules"]').prop('checked', false);
    //
    //                             $formMessageSuccess.css({ 'display' : 'none' });
    //                             $formStatus.css({ 'display' : 'none' });
    //                         }, 2000);
    //                     } else if (response.status === 'error') {
    //                         $formLoader.css({ 'display' : 'none' });
    //                         $formMessageError.css({ 'display' : 'block' });
    //                         setTimeout(function () {
    //                             $form.find('[name="first_name"]').val('');
    //                             $form.find('[name="email"]').val('');
    //                             $form.find('[name="subject"]').val('');
    //                             $form.find('[name="message"]').val('');
    //                             $form.find('[name="rules"]').prop('checked', false);
    //
    //                             $formMessageSuccess.css({ 'display' : 'none' });
    //                             $formStatus.css({ 'display' : 'none' });
    //                         }, 2000);
    //                     }
    //                 },
    //                 error: function () {
    //                     $formLoader.css({ 'display' : 'none' });
    //                     $formMessageError.css({ 'display' : 'block' });
    //                     setTimeout(function () {
    //                         $formMessageSuccess.css({ 'display' : 'none' });
    //                         $formStatus.css({ 'display' : 'none' });
    //                     }, 2000);
    //                 }
    //             });
    //         }, 1000);
    //     });
    // }
    //
    // var $faq_grid = $('body').find('.js-faq-grid');
    // var $single_faq = $faq_grid.find('[data-element="single-faq"]');
    // if ($single_faq !== null) {
    //     var $faq_button = $single_faq.find('[data-element="faq-button"]');
    //     if ($faq_button.length > 0) {
    //         $faq_button.on('click', function(e) {
    //             e.preventDefault();
    //
    //             var $parent = $(this).parent().parent();
    //             var $content = $parent.find('[data-element="faq-content"]');
    //             if ($parent.hasClass('visible')) {
    //                 $content.slideUp();
    //                 $parent.removeClass('visible');
    //             } else {
    //                 $content.slideDown();
    //                 $parent.addClass('visible');
    //             }
    //             // console.log('Clicked!');
    //         });
    //     }
    // }
    //
    // if (window.nt_cart_page === true) {
    //     setInterval(function() {
    //         cart.reloadCart();
    //     }, 5000);
    // }
    //
    // var cart_first_load = true;
    // if (cart_first_load) {
    //     console.log('index cart first load');
    //     cart.firstCartLoad();
    //     cart_first_load = false;
    // }
    // var $wc_tabs = $('.js-woocommerce-tabs-wrapper');
    // var $prod_sticky_bar = $('.js-product-sticky-bar');
    // var $sticky_nav = $('.js-sticky-navbar');
    // var $sticky_sidebar = $('.js-sticky-sidebar');
    // var $tablist = $wc_tabs.find('[data-element="tablist"]');
    // var $tabcontent = $wc_tabs.find('[data-element="content"]');
    //
    // var screen_res_width = $(window).width();
    // if (screen_res_width >= 768) {
    //     // Pokazywanie paska górnego w odpowiednim momencie
    //     $(window).on('scroll', function () {
    //         var current_scroll_top = $(window).scrollTop();
    //         if ($wc_tabs.length > 0) {
    //             var wc_tabs_height_from_top = $wc_tabs.offset().top;
    //             if (current_scroll_top >= wc_tabs_height_from_top - (2 * $sticky_nav.outerHeight()) && current_scroll_top <= (wc_tabs_height_from_top + $wc_tabs.outerHeight() - $prod_sticky_bar.outerHeight())) {
    //                 $tablist.css({'opacity': '0'});
    //                 if (!$prod_sticky_bar.hasClass('show')) {
    //                     $prod_sticky_bar.addClass('show');
    //                 }
    //                 // $sticky_sidebar.css({'top': '180px'});
    //                 $sticky_sidebar.addClass('top-180');
    //             } else {
    //                 $tablist.css({'opacity': '1'});
    //                 if ($prod_sticky_bar.hasClass('show')) {
    //                     $prod_sticky_bar.removeClass('show');
    //                 }
    //                 // $sticky_sidebar.css({'top': '70px'});
    //                 $sticky_sidebar.removeClass('top-180');
    //                 // $sticky_sidebar.removeClass('absolute');
    //             }
    //         }
    //
    //         // Synchronizacja tabów paska górnego ze scrollem treści
    //         var $tabs = $tabcontent.find('[id^="tab-"]');
    //         $tabs.each(function () {
    //             if (current_scroll_top >= ($(this).offset().top - (2 * $prod_sticky_bar.outerHeight())) && current_scroll_top <= $(this).offset().top + $(this).outerHeight()) {
    //                 $psb_tabs.find('>li').removeClass('active');
    //                 $psb_tabs.find('[data-target="' + $(this).attr('id') + '"]').parent().addClass('active');
    //             } else $psb_tabs.find('[data-target="' + $(this).attr('id') + '"]').parent().removeClass('active');
    //         });
    //     });
    //
    //     // Synchronizacja tabów WC z listą treści
    //     if ($tablist.length > 0) {
    //         $tablist.on('click', '[data-target]', function (e) {
    //             e.preventDefault();
    //
    //             var value = $(this).attr('data-target');
    //             var $target = $tabcontent.find('[id="' + value + '"]');
    //             $('html, body').animate({
    //                 scrollTop: $target.offset().top - ($prod_sticky_bar.outerHeight() + 30)
    //             }, 500);
    //         });
    //     }
    //
    //     // Sychronizacja tabów paska górnego z listą treści
    //     var $psb_tabs = $('.js-psb-tabs');
    //     if ($psb_tabs.length > 0) {
    //         $psb_tabs.on('click', '[data-target]', function (e) {
    //             e.preventDefault();
    //
    //             var value = $(this).attr('data-target');
    //             var $target = $tabcontent.find('[id="' + value + '"]');
    //             $('html, body').animate({
    //                 scrollTop: $target.offset().top - ($prod_sticky_bar.outerHeight() + 30)
    //             }, 500);
    //         });
    //     }
    // } else {
    //     $tabcontent.find('[data-element="mobile-tab-content"]').slideUp();
    //     $tabcontent.find('[data-element="mobile-clicker"]').removeClass('open');
    //     $tabcontent.find('.woocommerce-tabs-content__single-tab:first-of-type').find('[data-element="mobile-tab"]').addClass('open');
    //     $tabcontent.find('.woocommerce-tabs-content__single-tab:first-of-type').find('[data-element="mobile-clicker"]').addClass('open');
    //     $tabcontent.find('.woocommerce-tabs-content__single-tab:first-of-type').find('[data-element="mobile-tab-content"]').slideDown();
    //
    //     $tabcontent.on('click', '[data-element="mobile-tab"]', function(e) {
    //         e.preventDefault();
    //
    //         var $target = $(this);
    //         var $target_content = $target.parent().find('[data-element="mobile-tab-content"]');
    //         if ($target.hasClass('open')) {
    //             $target.removeClass('open');
    //             $target_content.slideUp();
    //         } else {
    //             $target.addClass('open');
    //             $target_content.slideDown();
    //         }
    //     });
    // }
    //
    // if ($(window).width() > 1000) {
    //     var $filter_button = $('body').find('.sidebar').find('[data-element="filters-button"]');
    //     var $filter_box = $('body').find('.js-filter-box');
    //     if ($filter_box.length > 0 && $filter_box.length > 0) {
    //         $filter_button.css({ 'width': $filter_button.parent().width() });
    //
    //         var filter_box_height_from_top = $filter_box.offset().top;
    //         var filter_box_height = $filter_box.outerHeight();
    //         var height_value = filter_box_height_from_top + filter_box_height;
    //         if (height_value > $(window).height()) {
    //             // console.log('1');
    //             if (!$filter_button.hasClass('fixed')) {
    //                 $filter_button.addClass('fixed')
    //             }
    //         } else console.log('2');
    //
    //         var current_scroll = null;
    //         var visible = true;
    //         $(window).on('scroll', function() {
    //             current_scroll = $(window).scrollTop() + $(window).height();
    //             // console.log(height_value, current_scroll);
    //
    //             if (current_scroll < height_value) {
    //                 if(!visible){
    //                     $filter_button.addClass('fixed');
    //                     visible = true;
    //                 }
    //             } else {
    //                 if(visible){
    //                     $filter_button.removeClass('fixed');
    //                     visible = false;
    //                 }
    //             }
    //         });
    //     }
    // }
    // jQuery(document.body).on('updated_cart_totals', function (event) {
    //     jQuery(document.body).find('.js-quantity').productQty();
    // });
    //
    // $('.js-inspiration').inspirationProducts();
    // $('.js-inspiration-filters').inspirationFilters();
    // $('.js-checkbox-list').filters();
});