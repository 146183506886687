import jQuery from 'jquery';
(function($){
    $.fn.mobileNavigation = function(){
        var $el = null,
            $clickers = null;

        var init = function(){
            $clickers = $el.find('[data-clicker]');

            $el.find('[data-clicker]').closest('a').next('ul').slideUp();

            $clickers.find('.active').parent().find('.mobile-navigaion--submenu-inside').css({ 'display' : 'block' });
            var $active_elements = $clickers.find('.active');
            console.log($active_elements);

            $clickers.on('click', function(e) {
                e.preventDefault();
                if (!$(this).closest('a').hasClass('active')) {
                    $(this).closest('a').addClass('active');
                    if ($(this).closest('a').next('ul').length > 0) {
                        $(this).closest('a').next('ul').slideDown();
                    }
                    $(this).text('-');
                } else {
                    $(this).closest('a').removeClass('active');
                    if ($(this).closest('a').next('ul').length > 0) {
                        $(this).closest('a').next('ul').slideUp();
                    }
                    $(this).text('+');
                }
            });
        };

        if(this.length > 0){
            if(this.length > 1){
                this.each(function(){
                    $(this).mobileNavigation();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);