import jQuery from 'jquery';
(function ($) {
    $.fn.dynamicTabs = function () {
        var $el = null;

        var $data = null;
        var $target = null;
        var callback = null;

        var init = function () {
            // console.log('e');
            $data = $el.find('[data-clicker]');
            $target = $el.find('[data-target]');
            $data.on('click', clickerEvent);
            callback = $el.attr('data-callback');

            $data.first().find('a').trigger('click');
            $target.each(function () {
                if (!$(this).hasClass('active')) {
                    $(this).hide();
                }
            });
        };

        var clickerEvent = function (e) {
            e.preventDefault();
            // console.log('click');
            var id = $(this).attr('data-clicker');

            $data.removeClass('active');
            $data.parent().removeClass('nav-tab--active');
            $(this).addClass('active');
            $(this).parent().addClass('nav-tab--active');

            $target.hide();
            $target.removeClass('active');
            $el.find('[data-target="' + id + '"]').show().addClass('active');

            if (typeof callback !== 'undefined') {
                window[callback]();
            }

        };
        if (this.length > 0) {
            if (this.length > 1) {
                this.each(function () {
                    $(this).dynamicTabs();
                })
            } else {
                $el = this;
                init();
            }
        }
    }
})(jQuery);